var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "90%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "2vh",
            title: "Lịch sử cấp SMS",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "table-content row-data" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    "highlight-current-row": "",
                    data: _vm.smsAgentList,
                    "header-cell-style": _vm.tableHeaderColor,
                    border: "",
                    "max-height": 500,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "STT",
                      width: "50",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "smsDate",
                      label: "Ngày cấp",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDateTime")(scope.row.smsDate)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "numberSms",
                      label: "Số lượng",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createBy",
                      label: "Người cấp",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "content",
                      label: "Nội dung",
                      align: "left",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }